import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
// import EyeIcon from "../assets/svg/EyeIcon";
import logo from "../assets/images/logo.png";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import NewsIcon from "../assets/images/news-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import LanguageChange from "../views/components/LanguageChange";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  const location = useLocation();
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "sports",
    "public-sports",
    "live-dealer",
    "evolution",
    "aviator",
    "sign-in",
    "/",
  ];
  const handleShow1 = () => setShow1(!show1);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      document.body.classList.add("light-theme");
    }
    loadNotifications();
  }, []);

  useEffect(() => {
    const matchedValue = headerPages.find((f) => {
      if (location?.pathname == "/public-sports") {
        return f === location?.pathname.split("/")?.[1];
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header header_bfrlgn">
        <div className="topHead">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center HeaderSection">
                  <Button
                    variant="primary menu-btn"
                    onClick={handleShow1}
                    // onClick={() => setShowLeftbarToggle((s) => !s)}
                    className="d-block d-md-none"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>

                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img src={logo} alt="logo" /> */}
                    </a>
                  </div>

                  <div className="header-left mx-lg-auto d-none d-xl-block">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      className="d-none"
                    />

                    <Navbar.Collapse id="basic-navbar-nav" className="d-none">
                      <Nav className="me-auto">
                        {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                        <Nav.Link
                          href="/sports"
                          className={
                            activePage == "sports" || activePage === "/"
                              ? "active"
                              : ""
                          }
                        >
                          Home
                        </Nav.Link>
                        <Nav.Link
                          href="/public-sports"
                          className={
                            activePage == "public-sports" ? "active" : ""
                          }
                        >
                          Sportsbook
                        </Nav.Link>
                        <Nav.Link
                          href="/live-dealer-public"
                          className={
                            activePage == "live-dealer" ? "active" : ""
                          }
                        >
                          Live Dealers
                        </Nav.Link>
                        <Nav.Link
                          href="/sign-in"
                          className={activePage == "sign-in" ? "" : ""}
                        >
                          Vivo
                        </Nav.Link>
                        <Nav.Link
                          href="/casino/vivo"
                          className={activePage == "sign-in" ? "" : ""}
                        >
                          Aviator
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </div>

                  <div className="headerRight ms-auto ms-xl-0">
                    {/* <div className="theme-btn me-2">
                    <buton type="button" className="btn" onClick={toggleClass}>
                      <img src={SunLight} alt="sun" className="light_theme" />
                      <img src={MoonLight} alt="moon" className="dark_theme" />
                    </buton>
                  </div> */}
                    <div>
                      {" "}
                      <ul className="" style={{ marginLeft: "5px" }}>
                        <li>
                          <GamesSearchModal />
                        </li>
                        <li className="mx-4">
                          <LanguageChange />
                        </li>
                      </ul>
                    </div>
                    <Button
                      variant="primary"
                      // onClick={() => {
                      //   navigate("/sign-in");
                      // }}
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="login_btn me-4"
                    >
                      Login
                    </Button>
                    <Button
                      variant="primary"
                      // onClick={() => {
                      //   navigate("/mobile-number");
                      // }}

                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="signup_btn"
                    >
                      Signup
                    </Button>

                    {/* <div className="searchbar">
                    <AiOutlineSearch />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoginModal
          show={showAuthModals.login}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
          handleModalShow={handleModalShow}
        />
        {showAuthModals.mobileNumber && (
          <MobileNumberModal
            show={showAuthModals.mobileNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.verifyNumber && (
          <MobileNumberVerifyModal
            show={showAuthModals.verifyNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.register && (
          <RegisterModal
            show={showAuthModals.register}
            handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
            handleModalShow={handleModalShow}
          />
        )}

        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
          }
          handleModalShow={handleModalShow}
        />
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
          }
          handleModalShow={handleModalShow}
        />

        {show1 && <MobLeftbar className="d-block d-md-none" />}
      </header>
      <div className="bottom_head">
        <ul>
          <li>
            <a href="/betby">Sportsbook</a>
          </li>
          <li>
            <a href="/public-sports">CRICKET</a>
          </li>
          <li>
            <a href="/public-sports">FOOTBALL</a>
          </li>
          <li>
            <a href="/public-sports">TENNIS</a>
          </li>
          <li>
            <a href="/public-sports">HORSE RACING</a>
          </li>
          <li>
            <a href="/public-sports">GREYHOUND RACING</a>
          </li>
          <li>
            <a href="/atlas-lobby">SPORTSBOOK2</a>
          </li>
          <li>
            <a href="/sign-in">INDIAN CARD GAMES</a>
          </li>
          <li>
            <a href="/sign-in">LIVE CASINO</a>
          </li>
          <li>
            <a href="/sign-in">SLOTS GAMES</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
